import React from 'react';
import DonationImpactIcon from '../../../public/static/icons/donation-impact.svg';
import HeartIcon from '../../../public/static/icons/heart.svg';
import { pushReturnToDonationPopup as gtmPushReturnToDonationPopup } from '../../../utils/gtm';
import { sessionStorageGetItem, sessionStorageRemoveItem } from '../../../utils/web-storage';
import Button from '../Button';

import styles from './index.module.scss';

class ReturnToDonationPopup extends React.Component {
  state = {
    showing: false,
    returnUrl: '',
  };

  onClick = () => {
    this.setState({ showing: false });
    sessionStorageRemoveItem('donationFormAttempt');
  };

  componentDidMount() {
    const returnUrl = sessionStorageGetItem('donationFormAttempt');
    // When the variable is set the popup should appear.
    if (returnUrl) {
      setTimeout(() => {
        gtmPushReturnToDonationPopup();
        this.setState({
          showing: true,
          returnUrl: returnUrl,
        });
      }, 3000);
    }
  }

  render() {
    const { showing, returnUrl } = this.state;
    if (!showing) {
      return null;
    }

    return (
      <div
        tabIndex="-1"
        className={styles['return-to-donation-popup']}
        data-component={`return-to-donation-popup`}
      >
        <a
          href={returnUrl}
          className={styles['return-to-donation-popup__accept']}
          data-analytics="return-to-donation-popup-accept"
          data-component="return-to-donation-popup-accept"
        >
          <DonationImpactIcon className={styles['return-to-donation-popup__icon']} />
          <HeartIcon className={styles['return-to-donation-popup__heart-icon']} />
          <span className={styles['return-to-donation-popup__accept-text']}>
            Complete your donation and make a difference
          </span>
          <Button size="small" onClick={() => {}}>
            Complete my donation
          </Button>
          {/*<button className={`button button-primary button-small`}>Complete my donation</button>*/}
        </a>
        <button
          onClick={this.onClick}
          className={styles['return-to-donation-popup__reject']}
          data-analytics="return-to-donation-popup-reject"
          data-component="return-to-donation-popup-reject"
        >
          <span>Not now</span>
        </button>
      </div>
    );
  }
}

export default ReturnToDonationPopup;
